<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="24" :xs="24" class="elel">
						<el-button type="primary" @click="dialogAdd = true">新增</el-button>
						<div class="readbox">
							<el-tag size="large">查询是否可用：</el-tag>
							<el-radio-group v-model="status" @change="radioes">
								<el-radio label="" border>全部</el-radio>
								<el-radio :label="1" border>是</el-radio>
								<el-radio :label="0" border>否</el-radio>
							</el-radio-group>
						</div>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="type" label="类型">
						<template v-slot="scope">
							<span v-if="scope.row.type == 0">现金提现</span>
							<span v-if="scope.row.type == 1">金币提现</span>
							<span v-if="scope.row.type == 2">元宝提现</span>
						</template>
					</el-table-column>
					<el-table-column prop="money" label="金额"></el-table-column>
					<el-table-column prop="levelLimit" label="最低等级"></el-table-column>
					<el-table-column prop="continueDayLimit" label="连续签到天数"></el-table-column>
					<el-table-column prop="rewardPlayedLimit" width="220" label="连续签到每日激励视频数量"></el-table-column>
					<el-table-column prop="stepDayLimit" label="10000步天数"></el-table-column>
					<el-table-column prop="gameNumLimit" label="游戏相关数量限制"></el-table-column>
					<el-table-column prop="remark" label="备注"></el-table-column>
					<el-table-column prop="avgEcpmLimit" label="平均ecpm限制"></el-table-column>
					<el-table-column label="是否可用">
						<template v-slot="scope">
							<el-switch
								@change="switchClick(scope.row)"
								v-model="scope.row.status"
								class="mb-2"
								style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
								:active-value="1"
								:inactive-value="0"
								active-text="是"
								inactive-text="否"
							/>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
							<el-button size="small" type="primary" plain @click="clcdelete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 新增-配置 -->
				<el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="140px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="金额" prop="money"><el-input-number v-model="ruleForm.money" :step="0.1" :min="0.1" placeholder="请输入金额" /></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="类型" prop="types">
									<el-select v-model="ruleForm.types" clearable placeholder="请选择类型">
										<el-option :key="0" label="现金提现" :value="0" />
										<el-option :key="1" label="金币提现" :value="1" />
										<el-option :key="2" label="元宝提现" :value="2" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="最低等级" prop="levelLimit">
									<el-input-number v-model="ruleForm.levelLimit" :min="0" placeholder="请输入最低等级" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="连续签到天数" prop="continueDayLimit">
									<el-input-number v-model="ruleForm.continueDayLimit" :min="0" placeholder="请输入连续签到天数" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="每日激励视频数量" prop="rewardPlayedLimit">
									<el-input-number v-model="ruleForm.rewardPlayedLimit" :min="0" placeholder="请输入每日激励视频数量" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="10000步天数" prop="stepDayLimit">
									<el-input-number v-model="ruleForm.stepDayLimit" :min="0" placeholder="请输入10000步天数" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="游戏相关数量限制" prop="stepDayLimit">
									<el-input-number v-model="ruleForm.gameNumLimit" :min="0" placeholder="请输入游戏相关数量限制" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="备注" prop="stepDayLimit"><el-input placeholder="请输入备注" v-model="ruleForm.remark"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="平均ecpm限制" prop="avgEcpmLimit">
									<el-input-number v-model="ruleForm.avgEcpmLimit" :min="0" placeholder="请输入平均ecpm限制" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-配置 -->
				<el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="140px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="金额" prop="money">
									<el-input-number v-model="EditruleForm.money" :step="0.1" :min="0.1" placeholder="请输入金额" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="类型" prop="types">
									<el-select v-model="EditruleForm.types" clearable placeholder="请选择类型">
										<el-option :key="0" label="现金提现" :value="0" />
										<el-option :key="1" label="金币提现" :value="1" />
										<el-option :key="2" label="元宝提现" :value="2" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="最低等级" prop="levelLimit">
									<el-input-number v-model="EditruleForm.levelLimit" :min="0" placeholder="请输入最低等级" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="连续签到天数" prop="continueDayLimit">
									<el-input-number v-model="EditruleForm.continueDayLimit" :min="0" placeholder="请输入连续签到天数" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="每日激励视频数量" prop="rewardPlayedLimit">
									<el-input-number v-model="EditruleForm.rewardPlayedLimit" :min="0" placeholder="请输入每日激励视频数量" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="10000步天数" prop="stepDayLimit">
									<el-input-number v-model="EditruleForm.stepDayLimit" :min="0" placeholder="请输入10000步天数" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="游戏相关数量限制" prop="gameNumLimit">
									<el-input-number v-model="EditruleForm.gameNumLimit" :min="0" placeholder="请输入游戏相关数量限制" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="备注" prop="remark"><el-input placeholder="请输入备注" v-model="EditruleForm.remark"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="平均ecpm限制" prop="avgEcpmLimit">
									<el-input-number v-model="EditruleForm.avgEcpmLimit" :min="0" placeholder="请输入平均ecpm限制" />
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'tradeWithdraw',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			status: '',
			// 新增配置
			dialogAdd: false,
			ruleForm: {
				money: null,
				levelLimit: null,
				continueDayLimit: null,
				rewardPlayedLimit: null,
				stepDayLimit: null,
				avgEcpmLimit: null,
				gameNumLimit: null,
				remark: '',
				types: null
			},
			rules: {
				money: [{ required: true, message: '请输入金额', trigger: 'blur' }],
				levelLimit: [{ required: true, message: '请输入最低等级', trigger: 'blur' }],
				continueDayLimit: [{ required: true, message: '请输入连续签到天数', trigger: 'blur' }],
				rewardPlayedLimit: [{ required: true, message: '请输入连续签到每日激励视频数量', trigger: 'blur' }],
				stepDayLimit: [{ required: true, message: '请输入10000步天数', trigger: 'blur' }],
				avgEcpmLimit: [{ required: true, message: '请输入平均ecpm限制', trigger: 'blur' }],
				gameNumLimit: [{ required: true, message: '请输入游戏相关数量限制', trigger: 'blur' }],
				remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
				types: [{ required: true, message: '请选择类型', trigger: 'change' }]
			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				money: '',
				levelLimit: '',
				continueDayLimit: '',
				rewardPlayedLimit: '',
				stepDayLimit: '',
				avgEcpmLimit: '',
				gameNumLimit: '',
				remark: '',
				types: null
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}

		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取提现配置
		this.tradeWithdraw();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取提现配置
				this.tradeWithdraw();
			}
		}
	},
	methods: {
		// 获取提现配置
		tradeWithdraw() {
			this.serloading = true;
			this.$HTTP.post(
				'tradeWithdrawChanceConfig/query',
				{
					status: this.status,
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		//查新是否可用
		radioes() {
			this.currentPage = 1;
			this.tradeWithdraw();
		},
		// 是否可用
		switchClick(row) {
			this.$HTTP.post(
				'tradeWithdrawChanceConfig/updateStatus',
				{
					id: row.id,
					status: row.status
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取配置
							this.tradeWithdraw();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 新增-配置
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'tradeWithdrawChanceConfig/add',
						{
							money: this.ruleForm.money,
							levelLimit: this.ruleForm.levelLimit,
							continueDayLimit: this.ruleForm.continueDayLimit,
							rewardPlayedLimit: this.ruleForm.rewardPlayedLimit,
							stepDayLimit: this.ruleForm.stepDayLimit,
							gameNumLimit: this.ruleForm.gameNumLimit,
							avgEcpmLimit: this.ruleForm.avgEcpmLimit,
							remark: this.ruleForm.remark,
							productId: this.productId,
							type: this.ruleForm.types
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取配置
									this.tradeWithdraw();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			this.EditruleForm.money = row.money;
			this.EditruleForm.levelLimit = row.levelLimit;
			this.EditruleForm.continueDayLimit = row.continueDayLimit;
			this.EditruleForm.rewardPlayedLimit = row.rewardPlayedLimit;
			this.EditruleForm.stepDayLimit = row.stepDayLimit;
			this.EditruleForm.avgEcpmLimit = row.avgEcpmLimit;
			this.EditruleForm.gameNumLimit = row.gameNumLimit;
			this.EditruleForm.remark = row.remark;
			this.EditruleForm.id = row.id;
			this.EditruleForm.types = row.type;
			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'tradeWithdrawChanceConfig/update',
						{
							id: this.EditruleForm.id,
							continueDayLimit: this.EditruleForm.continueDayLimit,
							money: this.EditruleForm.money,
							levelLimit: this.EditruleForm.levelLimit,
							rewardPlayedLimit: this.EditruleForm.rewardPlayedLimit,
							stepDayLimit: this.EditruleForm.stepDayLimit,
							gameNumLimit: this.EditruleForm.gameNumLimit,
							avgEcpmLimit: this.EditruleForm.avgEcpmLimit,
							remark: this.EditruleForm.remark,
							type: this.EditruleForm.types
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取配置
									this.tradeWithdraw();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 删除
		clcdelete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$HTTP.post(
						'tradeWithdrawChanceConfig/delete',
						{
							id: row.id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取提现配置
									this.tradeWithdraw();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取提现配置
			this.tradeWithdraw();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取提现配置
			this.tradeWithdraw();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
	width: 100%;
}
.readbox {
	display: flex;
	align-items: center;
	margin-left: 20px;
	span {
		margin-right: 10px;
		font-size: 16px;
	}
}
.elel {
	display: flex;
	align-items: center;
}
</style>
